import './Rank.css'
import Progress from '../components/Progress'

function Rank() {
  return (
    <div className="Rank">
      <h2>Here is how each class is doing:</h2>
        <Progress />
    </div>
  );
}

export default Rank

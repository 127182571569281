import './Success.css'
import Progress from '../components/Progress'

const Success = () => {
  return (
    <div className='Success'>
      <h2>Thanks for your donation to Class: <br/> Grade 4th Mrs. Martinez</h2>
      <Progress target="Grade 4th Mrs. Martinez" amount="200" />
    </div>
    
  )
}

export default Success
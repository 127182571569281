import './Donation.css'

const url_donation = 'https://westhoffcc-membership-api.shin.dev/donate'

const Donation = () => {

    const handleSubmit = (e) => {
        e.preventDefault()
        let donation = { 
            'class': e.target.class.value,
            'email': e.target.email.value,
            'student_name': e.target.student_name.value,
            'amount': e.target.amount.value,
        }
        fetch(url_donation, {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(donation)})
        .then(response => response.json())
        .then(data => {
            console.log(data)
            window.location.replace(data.redirection);
        })

    }

    return (
        <form className="Donation" onSubmit={handleSubmit}>
        <div className="form-group">
            <label htmlFor="inputClass">Class</label>
            <select className="form-control" id="inputClass" name="class">
                <option>Grade 4th Mrs Martinez</option>
                <option>Grade 4th Mrs xxxx</option>
                <option>Grade 4th Mrs xxxx</option>
                <option>Grade 5th Mrs xxxx</option>
                <option>Grade 5th Mrs xxxx</option>
            </select>
        </div>
        <div className="form-group">
            <label htmlFor="inputEmail">Email address</label>
            <input type="email" className="form-control" id="inputEmail" name="email" placeholder="Enter email"/>
        </div>
        <div className="form-group">
            <label htmlFor="inputStudentName">Student Name</label>
            <input type="text" className="form-control" id="inputStudentName" name="student_name" placeholder="Student Name"/>
        </div>
        <div className="form-group">
            <label htmlFor="inputAmount">Amount in Dollars</label>
            <input type="text" className="form-control" id="inputAmount" name="amount" placeholder="20" defaultValue="20"/>
        </div>
        <div className="form-group">
            <button type="submit" className="btn btn-primary">Submit</button>
        </div>
        </form>
  )
}

export default Donation

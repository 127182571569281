import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import 'bootstrap/dist/css/bootstrap.css'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Success from './routes/Success'
import Donation from './routes/Donation'
import Homepage from './routes/Homepage'
import Rank from './routes/Rank'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="success" element={<Success />} />
      <Route path="donation" element={<Donation />} />
      <Route path="rank" element={<Rank />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
)


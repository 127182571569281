import './Homepage.css'
import { useNavigate } from "react-router-dom"

function Homepage() {
  const navigate = useNavigate();
  function handleClick(path) {
    navigate(path);
  }

  return (
    <div className="Homepage">
      <h2>Westhoff Membership Drive!!</h2>
      <div className="btn-group" role="group" aria-label="Basic example">
        <button type="button" className="btn btn-primary" onClick={() => handleClick("donation")}>Donate</button>
        <button type="button" className="btn btn-success" onClick={() => handleClick("rank")}>Rank</button>
      </div>
      
    
    </div>
  );
}

export default Homepage

import './Progress.css'

const ProgressBar = (props) => {

  const percent = props.amount * 100 / 1000
  return (
    <div>
      <span>{props.name} - ${props.amount}</span>
      <div className="progress">
        <div className="progress-bar bg-danger" role="progressbar" style={{width: percent + '%'}} > {props.name} </div>
      </div>
    </div>
  )
}

const ProgressBarTarget = (props) => {

  const percent = props.amount * 100 / 1000
  return (
    <div>
      <span>{props.name} - ${props.amount}  + ${props.addedAmount} from you!! 🏆🏆🏆</span>
      <div className="progress">
        <div className="progress-bar bg-warning" role="progressbar" style={{width: percent + '%'}} > 📣📣📣{props.name}📣📣📣 </div>
      </div>
    </div>
  )
}

const Progress = (props) => {
  const classes = [
    { name: "Grade 4th Mrs. Martinez", amount: 1000},
    { name: "Grade 3rd Mrs. Tan", amount: 800},
    { name: "Grade 3rd Mrs. Tan", amount: 800},
    { name: "Grade 3rd Mrs. Tan", amount: 500},
    { name: "Grade 3rd Mrs. Tan", amount: 500},
    { name: "Grade 3rd Mrs. Tan", amount: 300},
    { name: "Grade 3rd Mrs. Tan", amount: 300},
    { name: "Grade 3rd Mrs. Tan", amount: 300},
    { name: "Grade 3rd Mrs. Tan", amount: 300},
    { name: "Grade 3rd Mrs. Tan", amount: 300},
    { name: "Grade 3rd Mrs. Tan", amount: 300},
    { name: "Grade 3rd Mrs. Tan", amount: 300},
  ]

  if (!props.target) {
    console.log("No target")
    return (
      <div className='Progress'>
        {classes.map((c,i) => <ProgressBar name={c.name} amount={c.amount} key={i} />)}
      </div>
    )
  }else{
    console.log("target: " + props.target)

    const target = classes.find(c => c.name === props.target)
    const restClasses = classes.filter((c)=> c.name !== props.target)

    return (
      <div className='Progress'>
        <ProgressBarTarget name={target.name} amount={target.amount} addedAmount={props.amount} />
        <br/>
        {restClasses.map((c,i) => <ProgressBar name={c.name} amount={c.amount} key={i} />)}
      </div>
      
    )
  }
}
  
export default Progress